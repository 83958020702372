import React from 'react';
import { useEffect, useState} from 'react';
import { useOktaAuth } from '@okta/okta-react';

function OnboardingWidget() {

  const [user, setUser] = useState(null);
  const [userAuthState, setUserAuthState] = useState({ isAuthenticated: null });
  const [access_token, setAccessToken] = useState(null);
  const { oktaAuth, authState } = useOktaAuth();
  let userInfo = useState(null);


  const frameStyle = {
    'display': 'block',
    'width': '100%',
    'border': 'none',
    'overflow-y':'auto',
    'overflow-x': 'hidden'
  };

  useEffect(()=>{

    const getUserData = async()=>{
      userInfo = await oktaAuth.getAccessToken();
      console.log(userInfo)
      setAccessToken(userInfo);
    }
    getUserData();
  }, [userInfo])

 
  
  return (

    <div className="page" style={{height:'80%'}}>
      <iframe 
        title="iframe" 
        id='iframe_id'
        height={1000}
        width='100%'
        src={`https://dynamic-solutions.io/app/aca-onboarding/page1-664b99de2aec597d3ac92b8d?embed=true&access_token=${access_token}`}
        seamless="seamless"
        scrolling="no"
        allowFullScreen = {true}
        style={frameStyle}
      >
      </iframe>
    </div>
  );
}
// adding private

export default OnboardingWidget;
